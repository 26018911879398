export default [

  {
    path: '/tingees',
    name: 'tingees',
    component: () => import('@/views/settings/tingee/list/TingeeList.vue'),
    meta: {
      resource: 'bank',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/settings/general/GeneralSetting.vue'),
    meta: {
      resource: 'general-setting',
      action: 'read',
    },
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/views/settings/template/list/TemplateList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/income-expense-templates',
    name: 'income-expense-templates',
    component: () => import('@/views/settings/income-expense-template/list/IncomeExpenseTemplateList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/zalo',
    name: 'zalo',
    component: () => import('@/views/settings/zalo/list/ZaloList.vue'),
    meta: {
      resource: 'zalo',
      action: 'read',
    },
  },
  {
    path: '/zalo/callback',
    name: 'zalo-callback',
    component: () => import('@/views/settings/zalo/callback/ZaloCallback.vue'),
    meta: {
      resource: 'zalo',
      action: 'read',
      layout: 'full',
    },
  },
];
